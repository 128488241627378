import React from 'react';
import { vendorCategories } from '../data/vendors';
import VendorCard from '../components/platform/VendorCard';

export default function Playbook() {
  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Platforms</h1>
        <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
          Add Platform
        </button>
      </div>

      {vendorCategories.map((category) => (
        <div key={category.name} className="space-y-4">
          <h2 className="text-lg font-medium text-gray-900">{category.name}</h2>
          <div className="space-y-4">
            {category.vendors.map((vendor) => (
              <VendorCard key={vendor.id} vendor={vendor} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}