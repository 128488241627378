import React from 'react';
import { Book, FileText, Image } from 'lucide-react';

export default function FixFinder() {
  return (
    <div>
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">
        Knowledge Base
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <div className="flex items-center mb-4">
            <Book className="h-6 w-6 text-blue-500" />
            <h2 className="ml-2 text-lg font-medium">Documentation</h2>
          </div>
          <p className="text-gray-600 mb-4">
            Access vendor documentation and technical guides
          </p>
          <button className="text-blue-600 hover:text-blue-700">
            Browse Docs →
          </button>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <div className="flex items-center mb-4">
            <FileText className="h-6 w-6 text-blue-500" />
            <h2 className="ml-2 text-lg font-medium">Knowledge Articles</h2>
          </div>
          <p className="text-gray-600 mb-4">
            Search through our database of solutions
          </p>
          <button className="text-blue-600 hover:text-blue-700">
            View Articles →
          </button>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <div className="flex items-center mb-4">
            <Image className="h-6 w-6 text-blue-500" />
            <h2 className="ml-2 text-lg font-medium">Resource Library</h2>
          </div>
          <p className="text-gray-600 mb-4">
            Browse diagrams, images, and visual guides
          </p>
          <button className="text-blue-600 hover:text-blue-700">
            Open Library →
          </button>
        </div>
      </div>
    </div>
  );
}