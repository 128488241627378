import { VendorCategory } from '../types/platform';

export const vendorCategories: VendorCategory[] = [
  {
    name: 'Computer & Server Vendors',
    vendors: [
      {
        id: 'dell',
        name: 'Dell',
        category: 'Hardware',
        description: 'Enterprise computing solutions and hardware',
        products: [
          {
            id: 'dell-laptops',
            name: 'Laptops',
            category: 'Computers',
            description: 'Business and consumer laptops',
          },
          {
            id: 'dell-desktops',
            name: 'Desktops',
            category: 'Computers',
            description: 'Business and consumer desktop computers',
          },
          {
            id: 'dell-poweredge',
            name: 'PowerEdge Servers',
            category: 'Servers',
            description: 'Enterprise server solutions',
          },
          {
            id: 'dell-powervault',
            name: 'PowerVault Storage',
            category: 'Storage',
            description: 'Enterprise storage solutions',
          },
        ],
      },
      {
        id: 'lenovo',
        name: 'Lenovo',
        category: 'Hardware',
        description: 'Business and consumer computing solutions',
        products: [
          {
            id: 'lenovo-thinkpad',
            name: 'ThinkPad',
            category: 'Laptops',
            description: 'Professional business laptops',
          },
          {
            id: 'lenovo-ideapad',
            name: 'IdeaPad',
            category: 'Laptops',
            description: 'Consumer laptops',
          },
          {
            id: 'lenovo-desktops',
            name: 'Desktops',
            category: 'Computers',
            description: 'Business and consumer desktops',
          },
          {
            id: 'lenovo-thinksystem',
            name: 'ThinkSystem',
            category: 'Servers',
            description: 'Enterprise servers and solutions',
          },
        ],
      },
      {
        id: 'hp',
        name: 'HP',
        category: 'Hardware',
        description: 'Comprehensive IT hardware and solutions',
        products: [
          {
            id: 'hp-laptops',
            name: 'Laptops',
            category: 'Computers',
            description: 'Business and consumer laptops',
          },
          {
            id: 'hp-desktops',
            name: 'Desktops',
            category: 'Computers',
            description: 'Business and consumer desktops',
          },
          {
            id: 'hp-proliant',
            name: 'ProLiant Servers',
            category: 'Servers',
            description: 'Enterprise server solutions',
          },
          {
            id: 'hp-printers',
            name: 'Printers',
            category: 'Peripherals',
            description: 'Professional and consumer printers',
          },
        ],
      },
      {
        id: 'apple',
        name: 'Apple',
        category: 'Hardware',
        description: 'Premium computing devices and solutions',
        products: [
          {
            id: 'apple-macbook',
            name: 'MacBook',
            category: 'Laptops',
            description: 'Professional laptops',
          },
          {
            id: 'apple-imac',
            name: 'iMac',
            category: 'Desktops',
            description: 'All-in-one desktop computers',
          },
          {
            id: 'apple-mac-pro',
            name: 'Mac Pro',
            category: 'Workstations',
            description: 'Professional workstation',
          },
          {
            id: 'apple-ios',
            name: 'iOS Devices',
            category: 'Mobile',
            description: 'iPhones and iPads',
          },
        ],
      },
    ],
  },
  {
    name: 'Network & Communications',
    vendors: [
      {
        id: 'cisco',
        name: 'Cisco',
        category: 'Networking',
        description: 'Enterprise networking solutions',
        products: [
          {
            id: 'cisco-routers',
            name: 'Routers',
            category: 'Networking',
            description: 'Enterprise routers',
          },
          {
            id: 'cisco-switches',
            name: 'Switches',
            category: 'Networking',
            description: 'Network switches',
          },
          {
            id: 'cisco-asa',
            name: 'ASA Firewalls',
            category: 'Security',
            description: 'Security appliances',
          },
          {
            id: 'cisco-voip',
            name: 'VoIP Phones',
            category: 'Communications',
            description: 'Voice over IP solutions',
          },
          {
            id: 'cisco-wap',
            name: 'Wireless Access Points',
            category: 'Networking',
            description: 'Enterprise wireless solutions',
          },
        ],
      },
      {
        id: 'juniper',
        name: 'Juniper Networks',
        category: 'Networking',
        description: 'Professional networking equipment',
        products: [
          {
            id: 'juniper-routers',
            name: 'Routers',
            category: 'Networking',
            description: 'Enterprise routing solutions',
          },
          {
            id: 'juniper-switches',
            name: 'Switches',
            category: 'Networking',
            description: 'Network switching solutions',
          },
          {
            id: 'juniper-security',
            name: 'Security Solutions',
            category: 'Security',
            description: 'Network security products',
          },
        ],
      },
      {
        id: 'aruba',
        name: 'Aruba (HPE)',
        category: 'Networking',
        description: 'Enterprise wireless solutions',
        products: [
          {
            id: 'aruba-wap',
            name: 'Wireless Access Points',
            category: 'Networking',
            description: 'Enterprise wireless access points',
          },
          {
            id: 'aruba-management',
            name: 'Network Management',
            category: 'Software',
            description: 'Network management solutions',
          },
        ],
      },
    ],
  },
  {
    name: 'Storage & Backup',
    vendors: [
      {
        id: 'netapp',
        name: 'NetApp',
        category: 'Storage',
        description: 'Enterprise storage solutions',
        products: [
          {
            id: 'netapp-nas',
            name: 'Network Attached Storage',
            category: 'Storage',
            description: 'Enterprise NAS solutions',
          },
          {
            id: 'netapp-management',
            name: 'Storage Management',
            category: 'Software',
            description: 'Storage management solutions',
          },
        ],
      },
      {
        id: 'emc',
        name: 'EMC (Dell Technologies)',
        category: 'Storage',
        description: 'Enterprise storage and data protection',
        products: [
          {
            id: 'emc-storage',
            name: 'Storage Solutions',
            category: 'Storage',
            description: 'Enterprise storage systems',
          },
          {
            id: 'emc-backup',
            name: 'Backup Solutions',
            category: 'Software',
            description: 'Data backup and recovery',
          },
        ],
      },
    ],
  },
  {
    name: 'Software & Virtualization',
    vendors: [
      {
        id: 'microsoft',
        name: 'Microsoft',
        category: 'Software',
        description: 'Enterprise software solutions',
        products: [
          {
            id: 'microsoft-windows',
            name: 'Windows Server',
            category: 'Software',
            description: 'Server operating system',
          },
          {
            id: 'microsoft-365',
            name: 'Office 365',
            category: 'Software',
            description: 'Productivity suite',
          },
          {
            id: 'microsoft-azure',
            name: 'Azure',
            category: 'Cloud',
            description: 'Cloud computing platform',
          },
          {
            id: 'microsoft-ad',
            name: 'Active Directory',
            category: 'Software',
            description: 'Directory services',
          },
        ],
      },
      {
        id: 'vmware',
        name: 'VMware',
        category: 'Virtualization',
        description: 'Virtualization and cloud solutions',
        products: [
          {
            id: 'vmware-vsphere',
            name: 'vSphere',
            category: 'Software',
            description: 'Virtualization platform',
          },
          {
            id: 'vmware-vcenter',
            name: 'vCenter',
            category: 'Software',
            description: 'Infrastructure management',
          },
          {
            id: 'vmware-workspace',
            name: 'Workspace ONE',
            category: 'Software',
            description: 'Digital workspace platform',
          },
        ],
      },
    ],
  },
  {
    name: 'Security',
    vendors: [
      {
        id: 'paloalto',
        name: 'Palo Alto Networks',
        category: 'Security',
        description: 'Network security solutions',
        products: [
          {
            id: 'paloalto-firewalls',
            name: 'Firewalls',
            category: 'Security',
            description: 'Next-gen firewalls',
          },
          {
            id: 'paloalto-endpoint',
            name: 'Endpoint Protection',
            category: 'Security',
            description: 'Endpoint security solutions',
          },
        ],
      },
      {
        id: 'sophos',
        name: 'Sophos',
        category: 'Security',
        description: 'Cybersecurity solutions',
        products: [
          {
            id: 'sophos-endpoint',
            name: 'Endpoint Protection',
            category: 'Security',
            description: 'Endpoint security',
          },
          {
            id: 'sophos-firewall',
            name: 'Firewalls',
            category: 'Security',
            description: 'UTM solutions',
          },
        ],
      },
    ],
  },
  {
    name: 'Peripherals & Devices',
    vendors: [
      {
        id: 'logitech',
        name: 'Logitech',
        category: 'Peripherals',
        description: 'Computer accessories and peripherals',
        products: [
          {
            id: 'logitech-keyboards',
            name: 'Keyboards',
            category: 'Peripherals',
            description: 'Professional keyboards',
          },
          {
            id: 'logitech-webcams',
            name: 'Webcams',
            category: 'Peripherals',
            description: 'Professional webcams',
          },
          {
            id: 'logitech-conference',
            name: 'Conference Solutions',
            category: 'Communications',
            description: 'Meeting room solutions',
          },
        ],
      },
      {
        id: 'poly',
        name: 'Poly',
        category: 'Communications',
        description: 'Professional communication solutions',
        products: [
          {
            id: 'poly-voip',
            name: 'VoIP Phones',
            category: 'Communications',
            description: 'Business phones',
          },
          {
            id: 'poly-headsets',
            name: 'Headsets',
            category: 'Audio',
            description: 'Professional headsets',
          },
          {
            id: 'poly-video',
            name: 'Video Conferencing',
            category: 'Communications',
            description: 'Conference room systems',
          },
        ],
      },
    ],
  },
];