import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Product } from '../../types/platform';

interface ProductCardProps {
  product: Product;
}

export default function ProductCard({ product }: ProductCardProps) {
  return (
    <div className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors">
      <div className="flex justify-between items-start">
        <div>
          <h4 className="font-medium text-gray-900">{product.name}</h4>
          <p className="text-sm text-gray-600 mt-1">{product.description}</p>
        </div>
        <button className="text-blue-600 hover:text-blue-700">
          <ArrowRight className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
}