import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { Vendor } from '../../types/platform';
import ProductCard from './ProductCard';

interface VendorCardProps {
  vendor: Vendor;
}

export default function VendorCard({ vendor }: VendorCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50"
      >
        <div className="flex items-center">
          <h3 className="text-lg font-medium text-gray-900">{vendor.name}</h3>
          <span className="ml-2 text-sm text-gray-500">{vendor.category}</span>
        </div>
        {isExpanded ? (
          <ChevronDown className="h-5 w-5 text-gray-400" />
        ) : (
          <ChevronRight className="h-5 w-5 text-gray-400" />
        )}
      </button>

      {isExpanded && (
        <div className="px-6 pb-4">
          <p className="text-sm text-gray-600 mb-4">{vendor.description}</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {vendor.products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}