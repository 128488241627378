import React from 'react';
import { NavLink } from 'react-router-dom';
import { Book, Boxes, Search, Laptop } from 'lucide-react';
import { clsx } from 'clsx';

const navigation = [
  { name: 'Playbook', icon: Book, href: '/' },
  { name: 'Modules', icon: Boxes, href: '/modules' },
  { name: 'FixFinder', icon: Search, href: '/fixfinder' },
];

export default function Sidebar() {
  return (
    <div className="flex flex-col w-64 bg-gray-900">
      <div className="flex items-center h-16 px-4 bg-gray-800">
        <Laptop className="h-8 w-8 text-blue-500" />
        <span className="ml-2 text-xl font-semibold text-white">FixFinder</span>
      </div>
      <nav className="flex-1 px-2 py-4 space-y-1">
        {navigation.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={({ isActive }) =>
              clsx(
                'flex items-center px-4 py-2 text-sm font-medium rounded-lg',
                isActive
                  ? 'bg-gray-800 text-white'
                  : 'text-gray-300 hover:bg-gray-800 hover:text-white'
              )
            }
          >
            <item.icon className="h-5 w-5 mr-3" />
            {item.name}
          </NavLink>
        ))}
      </nav>
    </div>
  );
}