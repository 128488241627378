import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import Playbook from '../pages/Playbook';
import Modules from '../pages/Modules';
import FixFinder from '../pages/FixFinder';
import UserMenu from './UserMenu';

export default function Layout() {
  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-white border-b border-gray-200">
          <div className="px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
            <div className="flex-1">
              <input
                type="search"
                placeholder="Search devices, issues, or documentation..."
                className="w-full max-w-lg h-10 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <UserMenu />
          </div>
        </header>
        <main className="flex-1 overflow-auto bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <Routes>
              <Route path="/" element={<Playbook />} />
              <Route path="/modules" element={<Modules />} />
              <Route path="/fixfinder" element={<FixFinder />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
}