import React from 'react';
import { Plus } from 'lucide-react';

export default function Modules() {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">
          Troubleshooting Modules
        </h1>
        <button className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
          <Plus className="h-5 w-5 mr-2" />
          Create Module
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <p className="text-gray-500 text-center py-8">
          Create custom troubleshooting wizards and workflows for your team.
          <br />
          Click the button above to get started.
        </p>
      </div>
    </div>
  );
}